import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import useAuth from '../hooks/useAuth';

export default function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const { currentUser, handleUserLogout } = useAuth();

  return (
    <nav className="sticky top-0 z-50 bg-dope-purple">
      <div className="flex justify-between px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        {/* Padre principal del navbar vista pc */}
        <div className="block w-full sm:grid grid-cols-[1fr_auto_1fr] items-center gap-4 py-5 justify-strech">
          {/* <div className="flex items-center"> */}
          <div className="hidden md:block">
            <div className="space-x-4">
              <Link
                to="/descripcion"
                className="px-3 py-2 text-sm font-medium text-white rounded-md hover:text-yellow-500"
              >
                Descripción
              </Link>
              <Link
                to="/derechos-autor"
                className="px-3 py-2 text-sm font-medium text-white rounded-md hover:text-yellow-500"
              >
                Derechos de autor
              </Link>
            </div>
          </div>
          <div className="flex-">
            <Link
              to="/"
              onClick={() => setIsOpen(false)}
            >
              <h1
                className="relative flex items-center max-w-lg gap-2 px-3 py-2 text-xl font-medium text-white rounded-md hover:text-yellow-500 grow"
              >
                Cancionero de Oración
              </h1>
            </Link>

          </div>
          <div className="justify-end hidden md:block">
            <div className="flex justify-end space-x-4">
              {currentUser ? (
                <>
                  <Link to="nueva-cancion" className="px-3 py-2 text-sm font-medium text-white rounded-md hover:text-yellow-500">
                    Subir nueva canción
                  </Link>
                  <button type="button" className="px-3 py-2 text-sm font-medium text-gray-300 rounded-md hover:text-white" onClick={handleUserLogout}>
                    Cerrar Sesión
                  </button>

                </>
              ) : (
                <Link to="login" className="px-3 py-2 text-sm font-medium text-white rounded-md hover:text-yellow-500">
                  Administración
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="flex -mr-2 md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            type="button"
            className="inline-flex items-center justify-center p-2 text-gray-400 bg-gray-900 rounded-md hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <span className="sr-only">
              Open main menu
            </span>
            {!isOpen ? (
              <svg
                className="block w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            ) : (
              <svg
                className="block w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            )}
          </button>
          {/* </div> */}
        </div>
      </div>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref) => (
          <div className="md:hidden" id="mobile-menu">
            <div ref={ref} className="px-5 pt-2 pb-3 space-y-1 sm:px-3">
              <Link
                onClick={() => setIsOpen(!isOpen)}
                to="/descripcion"
                className="block px-3 py-2 text-base font-medium text-white rounded-md hover:text-yellow-500"
              >
                Descripción
              </Link>
              <Link
                onClick={() => setIsOpen(!isOpen)}
                to="/derechos-autor"
                className="block px-3 py-2 text-base font-medium text-white rounded-md hover:text-yellow-500"
              >
                Derechos de autor
              </Link>
              {currentUser ? (
                <>
                  <Link
                    onClick={() => setIsOpen(!isOpen)}
                    to="nueva-cancion"
                    className="block px-3 py-2 text-base font-medium text-white rounded-md hover:text-yellow-500"
                  >
                    Subir nueva canción
                  </Link>
                  <button
                    type="button"
                    className="block px-3 py-2 text-base font-medium text-gray-300 rounded-md hover:text-white"
                    onClick={() => {
                      setIsOpen(!isOpen);
                      handleUserLogout();
                    }}
                  >
                    Cerrar Sesión
                  </button>

                </>
              ) : (
                <Link
                  onClick={() => setIsOpen(!isOpen)}
                  to="login"
                  className="block px-3 py-2 text-base font-medium text-white rounded-md hover:text-yellow-500"
                >
                  Administración
                </Link>
              )}
            </div>
          </div>
        )}
      </Transition>
    </nav>
  );
}
