import React from 'react';

export default function Description() {
  return (
    <div className="px-2 py-5">
      <div className="overflow-x-auto">
        <div className="max-w-xl mx-auto overflow-hidden bg-white divide-y divide-gray-300 rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2
              className="pb-4 text-2xl font-bold text-center text-dope-purple"
            >
              Descripción
            </h2>

            <div className="py-2 text-justify indent-8">
              <p className="font-bold">Propósito</p>
              <p>
                La Fundación Educulto, para incentivar la piedad y el canto litúrgico, y para permitir un acceso fácil, directo y moderno a los cantos durante la misa, vio conveniente impulsar una edición actualizada y digital de un cancionero anterior (Cancionero de oración) en formato impreso. Una versión digital tiene la ventaja de admitir la actualización constante de los recursos y la posibilidad de conseguir una más amplia difusión.
              </p>
            </div>

            <div className="py-2 text-justify indent-8">
              <p className="font-bold">Rasgos y usos</p>
              <ol type="a">
                <li> a) El cancionero tiene filtros por categoría (momentos y tiempos litúrgicos) y por los títulos de los cantos.</li>
                <li> b) La página ofrece la lectura de la sola letra, o de la partitura completa. Para eso, el usuario debe escoger la pestaña letra o partitura.   </li>
              </ol>
            </div>

            <div className="py-2 text-justify indent-8">
              <p className="font-bold">Equipo</p>
              <p>
                En la elaboración de esta obra han participado:
                {' '}
                <span className="font-bold">Ángela Guevara</span>
                , como coordinadora;
                {' '}
                <span className="font-bold">Luis Vidal</span>
                , músico, adaptador de las partituras del cancionero anterior y de las que se han incorporado a esta versión;
                {' '}
                <span className="font-bold">José Antonio Castro</span>
                , programador de la página web que aloja el cancionero;
                {' '}
                <span className="font-bold">Tatiana Berckhoff</span>
                , periodista, encargada de la edición de las letras y de verificar lo relacionado con la propiedad intelectual.
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
