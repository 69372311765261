const categories = [
  'Entrada',
  'Acto penitenciario',
  'Gloria',
  'Salmos',
  'Proclamación del Evangelio',
  'Ofertorio',
  'Santo',
  'Fracción del pan',
  'Comunión',
  'Salida',
  'Santísima Trinidad',
  'Virgen María',
  'San José',
  'Navidad',
  'Cuaresma/Semana Santa',
  'Tiempo Pascual',
];

const liturgicalTerms = {
  'Entrada': 'ENTRADA',
  'Acto penitenciario': 'ACTO PENITENCIAL',
  'Gloria': 'GLORIA',
  'Salmos': 'SALMOS',
  'Proclamación del Evangelio': 'PROCLAMACIÓN EVANGELIO',
  'Ofertorio': 'PRESENTACIÓN OFRENDAS',
  'Santo': 'SANTO',
  'Fracción del pan': 'RITO DE COMUNIÓN',
  'Comunión': 'COMUNIÓN',
  'Salida': 'SALIDA',
  'Santísima Trinidad': 'SANTÍSIMA TRINIDAD',
  'Virgen María': 'VIRGEN MARÍA',
  'San José': 'SAN JOSÉ',
  'Navidad': 'ADVIENTO-NAVIDAD',
  'Cuaresma/Semana Santa': 'CUARESMA-SEMANA SANTA',
  'Tiempo Pascual': 'PASCUA-PENTECOSTÉS',
};


export {categories, liturgicalTerms};
