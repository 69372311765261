import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import axios from 'axios';
import useAuth from '../hooks/useAuth';
import SongForm from '../components/SongForm';
// import GoBack from '../components/GoBack';
import Loading from '../components/temporary/Loading';
import Error from '../components/temporary/Error';

export default function SongEdit() {
  const { currentUser } = useAuth();
  const { orderId } = useParams();

  const [song, setSong] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetchSong = async () => {
    setLoading(true);
    const options = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/api/v1/songs/${orderId}`,
    };
    axios.request(options).then((response) => {
      setSong(response.data);
    })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchSong();
  }, []);

  if (!currentUser) return <Navigate to="/login" />;

  if (loading) return <Loading />;

  if (error) return <Error error={error} />;

  return (
    <section>
      {/* <GoBack /> */}
      <SongForm initialValues={song} method="PATCH" buttonText="Editar Canción" color="green" />
    </section>
  );
}
