import React from 'react';
import { useParams } from 'react-router-dom';
import SinglePDF from '../components/SinglePDF';

function PDFView(props) {
  const { type } = props;
  const { orderId } = useParams();
  return (
    <section>
      <SinglePDF orderId={orderId} type={type} />
    </section>
  );
}

export default PDFView;
