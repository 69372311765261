import React from 'react';

export default function Error({ error }) {
  return (
    <div className="w-full max-w-sm mx-auto mt-4">
      <div className="px-8 pt-6 pb-8 mb-4 bg-white rounded-md shadow-md">
        <h2 className="text-red-600">
          Hubo un error, intente más tarde
          <br />
          {error}
        </h2>
      </div>
    </div>
  );
}
