import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AuthContextProvider from './contexts/AuthContext';
import HomeView from './views/HomeView';
import PDFView from './views/PDFView';
import Description from './views/Description';
import Copyright from './views/Copyright';
import LogIn from './views/LogIn';
import SongNew from './views/SongNew';
import SongEdit from './views/SongEdit';
import NavBar from './components/NavBar';

import './assets/fonts/Iceberg-Regular.ttf';

function App() {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <NavBar />
        <div>
          <Routes>
            <Route index element={<HomeView />} />
            <Route path="letra/:orderId" element={<PDFView type="lyrics" />} />
            <Route path="partitura/:orderId" element={<PDFView type="sheets" />} />
            <Route path="login" element={<LogIn />} />
            <Route path="nueva-cancion" element={<SongNew />} />
            <Route path="cancion/:orderId/edit" element={<SongEdit />} />
            <Route path="derechos-autor" element={<Copyright />} />
            <Route path="descripcion" element={<Description />} />
          </Routes>
        </div>
      </AuthContextProvider>
    </BrowserRouter>
  );
}

export default App;
