import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import SongForm from '../components/SongForm';
// import GoBack from '../components/GoBack';

export default function SongNew() {
  const { currentUser } = useAuth();
  const initialValues = {
    name: '',
    lyricsURL: '',
    sheetsURL: '',
    category: '',
  };

  if (!currentUser) return <Navigate to="/login" />;

  return (
    <section>
      {/* <GoBack /> */}
      <SongForm initialValues={initialValues} method="POST" buttonText="Nueva Canción" color="blue" />
    </section>
  );
}
