import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BsSearch } from 'react-icons/bs';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import useAuth from '../hooks/useAuth';
import Loading from './temporary/Loading';
import Error from './temporary/Error';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { categories, liturgicalTerms } from '../categories';

function ListPDF() {
  const { currentUser } = useAuth();
  const [songs, setSongs] = useState([]);
  const [allSongs, setAllSongs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [searchLetters, setSearchLetters] = useState('');
  const [selected, setSelected] = useState('');

  const fetchSongs = async () => {
    setLoading(true);
    const options = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/api/v1/songs`,
    };
    axios.request(options).then((response) => {
      setSongs(response.data);
      setAllSongs(response.data);
    })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchSongs();
  }, []);

  const onChange = (event) => {
    const filteredSongs = allSongs.filter((song) => {
      const songName = song.name.toLowerCase();
      return songName.startsWith(event.target.value.toLowerCase());
    });
    setSongs(filteredSongs);
    setSearchLetters(event.target.value.toLowerCase());
    setSelected('');
  };

  const selectChange = (event) => {
    const filteredSongs = allSongs.filter((song) => {
      const selectedCategory = event.target.value;
      return selectedCategory === song.category || selectedCategory === '';
    });
    setSongs(filteredSongs);
    setSelected(event.target.value);
    setSearchLetters('');
  };

  const deleteSong = async (orderId) => {
    setLoading(true);
    const options = {
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_URL}/api/v1/songs/${orderId}`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${currentUser.access_token}`,
      },
    };
    axios.request(options).then(() => {
      fetchSongs();
    })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => setLoading(false));
  };

  const onClickDelete = (song) => {
    confirmAlert({
      title: 'Confirmar eliminación',
      message: `¿Está seguro/a de que desea eliminar la canción "${song.name}"?`,
      buttons: [
        {
          label: 'Sí',
          onClick: () => {
            deleteSong(song.orderId);
            alert('Canción eliminada');
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };

  if (loading) return <Loading />;

  if (error) return <Error error={error} />;

  return (
    <section>
      <div className="max-w-md mx-auto mt-4 mb-2 text-sm font-medium text-gray-900 dark:text-gray-900">
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <BsSearch />
          </div>
          <input
            type="text"
            className="block w-full p-4 pl-10 text-sm text-gray-800 placeholder-gray-400 bg-white border border-gray-600 rounded-lg focus:ring-blue-500 focus:border-blue-500"
            placeholder="Buscar canciones por nombre"
            value={searchLetters}
            onChange={onChange}
          />
        </div>
        <div
          className="flex items-center justify-center"
        >
          <select
            value={selected}
            onChange={selectChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            <option value="">Todas las Categorías</option>
            {categories.map((category) => (
              <option value={category}>{liturgicalTerms[category]}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="px-1 py-5">
        <div className="overflow-x-auto">
          <table className="w-full max-w-3xl mx-auto overflow-hidden bg-white divide-y divide-gray-300 rounded-lg">
            <thead className="bg-gray-900">
              <tr className="text-left text-white whitespace-nowrap">
                <th className="px-6 py-4 text-lg font-semibold"> n ° </th>
                {/* "IcebergRegular" */}
                <th className="px-3 py-4 text-lg font-semibold "> Canción </th>
                <th className="px-3 py-4 text-lg font-semibold text-center "> Recursos </th>
                <th className="px-6 py-4 text-lg font-semibold text-center">  </th>
                <th className="px-6 py-4 text-lg font-semibold text-center">  Categoría </th>
                {currentUser && (
                  <>
                    <th className="px-6 py-4 text-lg font-semibold "> Acciones </th>
                    <th className="px-6 py-4 text-lg font-semibold">  </th>
                  </>
                )}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {songs.map((song) => (
                <tr className="max-w-xs break-words">
                  <td className="px-6 py-4">
                    <div className="flex items-center space-x-3">
                      <p>
                        {song.orderId}
                        {') '}
                      </p>
                    </div>
                  </td>
                  <td
                    className="px-3 py-4 break-normal"
                  >
                    <p>
                      {song.name}
                    </p>
                  </td>
                  <td className="px-3 py-4 text-center">
                    <p className="text-blue-600 visited:text-purple-600 hover:text-blue-800 hover:underline">
                      {song.lyricsURL ? (
                        <Link to={`letra/${song.orderId}`}>
                          Letra
                        </Link>
                      ) : (<p>-</p>)}
                    </p>
                  </td>
                  <td className="px-3 py-4 text-center">
                    <p className="text-blue-600 visited:text-purple-600 hover:text-blue-800 hover:underline">
                      {song.sheetsURL ? (
                        <Link to={`partitura/${song.orderId}`}>
                          Partitura
                        </Link>
                      ) : (<p>-</p>)}
                    </p>
                  </td>
                  <td className="px-3 py-4 text-center">
                    <p>
                      {liturgicalTerms[song.category]}
                    </p>
                  </td>
                  {currentUser && (
                    <td className="px-3 py-4 text-center">
                      <p>
                        <span className="text-white text-sm py-1.5 bg-green-500 font-semibold px-2 rounded-full">
                          <Link to={`cancion/${song.orderId}/edit`}>
                            Editar
                          </Link>
                        </span>
                      </p>
                    </td>
                  )}
                  {currentUser && (
                    <td className="px-3 py-4 text-center">
                      <p>
                        <span className="text-white text-sm py-1.5 bg-red-600 font-semibold px-2 rounded-full">
                          {' '}
                          <button onClick={() => onClickDelete(song)} id="DeleteSong" type="submit">Eliminar</button>
                          {' '}
                        </span>
                      </p>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default ListPDF;
