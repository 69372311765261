import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import '../styles/PDF.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import axios from 'axios';
import ChangeTypeButton from './ChangeType';
import Loading from './temporary/Loading';
import Error from './temporary/Error';

function SinglePDF(props) {
  const { orderId, type } = props;

  const [fileURL, setFileURL] = useState('');
  const [numPages, setNumPages] = useState(null);

  const [song, setSong] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetchSong = async () => {
    setLoading(true);
    const options = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/api/v1/songs/${orderId}`,
    };
    axios.request(options).then((response) => {
      setSong(response.data);
      if (type === 'lyrics') {
        setFileURL(response.data.lyricsURL);
      } else {
        setFileURL(response.data.sheetsURL);
      }
    })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => setLoading(false));
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    fetchSong();
  }, []);

  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch(fileURL).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        const alink = document.createElement('a');
        alink.href = fileURL;
        const tipo = type === 'lyrics' ? 'Letra' : 'Partitura';
        alink.download = `${song.name}_${tipo}.pdf`;
        alink.click();
      });
    });
  };

  if (loading) return <Loading />;

  if (error) return <Error error={error} />;

  return (
    <section>
      <header className="bg-blue-400">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="text-center text-white ">
            <ChangeTypeButton
              orderId={orderId}
              setFileURL={setFileURL}
              song={song}
              fileURL={fileURL}
            />
          </div>
        </div>
      </header>
      <header className="bg-dope-gray">
        <div className="flex px-4 py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex-1 ">
            <h2 className="text-3xl font-bold text-center text-white ">
              {song.orderId}
              {') '}
              {song.name}
            </h2>
          </div>
        </div>
      </header>
      <Document className="PDFDocument" file={fileURL} onLoadSuccess={onDocumentLoadSuccess}>
        {[...Array(numPages)].map((_, i) => <Page className="PDFPage PDFPageOne" pageNumber={i + 1} renderTextLayer={false} renderInteractiveForms={false} />)}
      </Document>
      {(numPages !== null && song.downloadable === true)
        && (
          <div className="flex flex-row justify-center items-center mb-4">
            <button
              type="button"
              onClick={onButtonClick}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full"
            >
              Descargar PDF
            </button>
          </div>
        )}
    </section>
  );
}

export default SinglePDF;
