import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { GiLoveSong } from 'react-icons/gi';
import { FiUpload } from 'react-icons/fi';
import { BiCategory } from 'react-icons/bi';
import useAuth from '../hooks/useAuth';
import Loading from './temporary/Loading';
import { categories, liturgicalTerms } from '../categories';

export default function SongForm(props) {
  const {
    initialValues, method, buttonText, color,
  } = props;

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const { currentUser } = useAuth();

  const SUPPORTED_FORMATS = ['application/pdf'];
  const FILE_SIZE = 1.5e+7;

  function checkFileSize(file) {
    if (!file) return true;
    return file.size <= FILE_SIZE;
  }
  function checkFileFormat(file) {
    if (!file) return true;
    return SUPPORTED_FORMATS.includes(file.type);
  }

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(2, 'El nombre necesita al menos 2 caracteres')
      .max(50, 'El nombre no puede tener más de 50 caracteres')
      .required('El nombre es obligatorio'),
    category: Yup.string()
      .min(2, 'La categoría es obligatoria')
      .required('La categoría es obligatoria'),
    sheets: Yup.mixed()
      .test('fileSize', 'Documento muy grande, debe ser menor a 15 megabytes', (value) => checkFileSize(value))
      .test('fileType', 'Sólo se pueden subir documentos .pdf', (value) => checkFileFormat(value)),
    lyrics: Yup.mixed()
      .test('fileSize', 'Documento muy grande, debe ser menor a 15 megabytes', (value) => checkFileSize(value))
      .test('fileType', 'Sólo se pueden subir documentos .pdf', (value) => checkFileFormat(value)),
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('category', values.category);
    formData.append('sheets', values.sheets);
    formData.append('lyrics', values.lyrics);
    const options = {
      method,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${currentUser.access_token}`,
      },
      body: formData,
    };
    try {
      let response;
      if (method === 'POST') {
        response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/songs`, options);
      } else {
        response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/songs/${initialValues.orderId}`, options);
      }
      if (!response.ok) {
        const error = await response.text();
        throw new Error(error);
      }
      setMessage('Songs has been sucesesfully created');
      navigate('/');
    } catch (error) {
      setMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteFile = async (orderId, file) => {
    setLoading(true);
    const options = {
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_URL}/api/v1/songs/file/${file}/${orderId}`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${currentUser.access_token}`,
      },
    };
    axios.request(options).then(() => {
      navigate('/');
    })
      .catch((err) => {
        setMessage(err.message);
      })
      .finally(() => setLoading(false));
  };

  if (loading) return <Loading />;

  return (
    <div className="w-full max-w-sm mx-auto mt-4">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors, touched, setFieldValue, values,
        }) => (
          <Form className="px-8 pt-6 pb-8 mb-4 bg-white rounded-md shadow-md">
            <h1 className="text-2xl font-bold text-center text-black ">
              {(method === 'POST')
                ? <p>Agregar canción </p>
                : (
                  <p className="max-w-sm break-words">
                    Editando &quot;
                    {initialValues.name}
                    &quot;
                  </p>
                )}
            </h1>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-800"
              >
                Nombre de la cancion
                <div className="flex">
                  <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-400 rounded-l-md ">
                    <GiLoveSong />
                  </span>
                  <Field
                    type="text"
                    name="name"
                    placeholder="Nombre de la cancion"
                    className="rounded-none rounded-r-lg bg-gray-50 border border-gray-600 text-gray-700 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm  p-2.5 "
                  />
                </div>
                {(errors.name && touched.name) && (
                  <p>{errors.name}</p>
                )}
              </label>
            </div>

            <div className="mb-4">
              <label
                htmlFor="category"
                className="block mb-2 text-sm font-medium text-gray-800"
              >
                Categoria
                <div className="flex">
                  <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-400 rounded-l-md ">
                    <BiCategory />
                  </span>
                  <Field
                    as="select"
                    name="category"
                    className="rounded-none rounded-r-lg bg-gray-50 border border-gray-600 text-gray-700 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm  p-2.5 "
                  >
                    <option value="">Seleciona una categoria</option>
                    {categories.map((category) => (
                      <option value={category}>{liturgicalTerms[category]}</option>
                    ))}
                  </Field>

                </div>
                {(errors.category && touched.category) && (
                  <p>{errors.category}</p>
                )}
              </label>
            </div>

            <div className="mb-4">
              <label
                id="lyrics"
                htmlFor="upload-file-1"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                PDF Letra
                <input
                  hidden
                  id="upload-file-1"
                  type="file"
                  name="lyrics"
                  onChange={(event) => {
                    setFieldValue('lyrics', event.currentTarget.files[0]);
                  }}
                />
                <div
                  className="flex mt-1 text-sm text-gray-500 dark:text-gray-300"
                  id="lyrics"
                >
                  <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-400 rounded-l-md ">
                    <FiUpload />
                  </span>
                  <span className="rounded-none rounded-r-lg bg-gray-50 border border-gray-600 text-gray-700 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm  p-2.5 ">
                    {values.lyrics ? (
                      values.lyrics.name
                    ) : (
                      values.lyricsURL ? (<h4>Actualizar Letra...</h4>)
                        : (
                          <h4>Selecciona el PDF de la letra...</h4>
                        )
                    )}
                  </span>
                </div>
                {values.lyricsURL !== '' && (
                  <div
                    className="flex mt-1 text-sm text-gray-500 dark:text-gray-300"
                  >
                    <div className="rounded-lg  bg-gray-50 border border-gray-600 text-gray-700 block flex-1 min-w-0 w-full text-xs  p-2.5 ">
                      <p className="text-blue-600 visited:text-purple-600 hover:text-blue-800 hover:underline">
                        <Link to={`/letra/${values.orderId}`}>
                          Ver Letra Actual
                        </Link>
                      </p>
                    </div>
                    <div className="inline-flex items-center px-3 text-xs text-gray-900 bg-red-600 border border-gray-400 rounded-md ">
                      <p className="text-white">
                        <button onClick={() => deleteFile(values.orderId, 'lyrics')} id="DeleteSong" type="submit">
                          Eliminar Letra Actual
                        </button>
                      </p>
                    </div>
                  </div>
                )}

                {(errors.lyrics && touched.lyrics) && (
                  <p>{errors.lyrics}</p>
                )}
              </label>
            </div>

            <div className="mb-4">
              <label
                id="sheets"
                htmlFor="upload-file-2"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                PDF Partitura
                <input
                  hidden
                  id="upload-file-2"
                  type="file"
                  name="sheets"
                  onChange={(event) => {
                    setFieldValue('sheets', event.currentTarget.files[0]);
                  }}
                />
                <div
                  id="sheets"
                  className="flex mt-1 text-sm text-gray-500 dark:text-gray-300"
                >
                  <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-400 rounded-l-md ">
                    <FiUpload />
                  </span>
                  <span className="rounded-none rounded-r-lg bg-gray-50 border border-gray-600 text-gray-700 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm  p-2.5 ">
                    {values.sheets ? (
                      values.sheets.name
                    ) : (
                      values.sheetsURL ? (<h4>Actualizar Partitura...</h4>)
                        : (
                          <h4>Selecciona el PDF de la partitura...</h4>
                        )
                    )}
                  </span>
                </div>

                {values.sheetsURL !== '' && (
                  <div
                    className="flex mt-1 text-sm text-gray-500 dark:text-gray-300"
                  >
                    <div className="rounded-lg  bg-gray-50 border border-gray-600 text-gray-700 block flex-1 min-w-0 w-full text-xs p-2.5 ">
                      <p className="text-blue-600 visited:text-purple-600 hover:text-blue-800 hover:underline">
                        <Link to={`/partitura/${values.orderId}`}>
                          Ver Partitura Actual
                        </Link>
                      </p>
                    </div>
                    <div className="inline-flex items-center px-3 text-xs text-gray-900 bg-red-600 border border-gray-400 rounded-md ">
                      <p className="text-white">
                        <button onClick={() => deleteFile(values.orderId, 'sheets')} id="DeleteSong" type="submit">
                          Eliminar Partitura Actual
                        </button>
                      </p>
                    </div>
                  </div>
                )}

                {(errors.sheets && touched.sheets) && (
                  <p>{errors.sheets}</p>
                )}
              </label>
            </div>

            <div className="flex items-center justify-between">
              <button
                type="submit"
                className={`px-4 py-2 font-bold text-white bg-${color}-500 rounded hover:bg-${color}-700 focus:outline-none focus:shadow-outline`}

              >
                {buttonText}
              </button>
            </div>
            <p>{message}</p>
          </Form>
        )}
      </Formik>
    </div>
  );
}
