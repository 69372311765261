import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export default function ChangeTypeButton(props) {
  const {
    orderId, fileURL, setFileURL, song,
  } = props;
  const navigate = useNavigate();
  const [text, setText] = useState('');

  useEffect(() => {
    if (fileURL === song.lyricsURL && song.sheetsURL !== '') {
      setText('Ver partitura');
    } else if (fileURL === song.sheetsURL && song.lyricsURL !== '') {
      setText('Ver letra');
    }
  }, []);

  const handleClick = useCallback(() => {
    if (fileURL === song.lyricsURL) {
      setFileURL(song.sheetsURL);
      setText('Ver letra');
      navigate(`/partitura/${orderId}`);
    } else if (fileURL === song.sheetsURL) {
      setFileURL(song.lyricsURL);
      setText('Ver partitura');
      navigate(`/letra/${orderId}`);
    }
  });

  return (
    <div>
      <button type="button" onClick={handleClick}>{text}</button>
    </div>
  );
}
