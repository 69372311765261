import React from 'react';
// import { Link } from 'react-router-dom';
import ListPDF from '../components/ListPDF';
// import useAuth from '../hooks/useAuth';

function HomeView() {
  // const { currentUser, handleUserLogout } = useAuth();
  return (
    <section>
      <ListPDF />
    </section>
  );
}

export default HomeView;
