import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { MdAlternateEmail } from 'react-icons/md';
import { RiLockPasswordFill } from 'react-icons/ri';
import axios from 'axios';
import useAuth from '../hooks/useAuth';
import Loading from '../components/temporary/Loading';

const initialValues = {
  email: '',
  password: '',
};

export default function LogIn() {
  const [values, setValues] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { currentUser, handleUserLogin } = useAuth();

  const handleSubmit = async (values) => {
    setLoading(true);
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/api/v1/session`,
      data: values,
    };

    axios.request(options).then((response) => {
      const logInUser = response.data;
      handleUserLogin(logInUser);
    })
      .catch((err) => {
        setError(err.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  const handleChange = (event) => {
    setValues((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value,
    }));
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Email invalido')
      .required('Debes escribir un email'),
    password: Yup.string()
      .min(6, 'La contraseña debe tener al menos 6 caracteres')
      .max(30, 'La contraseña no puede tener más de 30 caracteres')
      .required('Debes escribir una contraseña'),
  });

  if (loading) return <Loading />;

  if (currentUser) return <Navigate to="/" />;

  return (
    <div className="w-full max-w-sm mx-auto mt-4">
      <div className="px-4 py-3 mb-2 text-teal-900 bg-teal-100 border-t-4 border-teal-500 rounded-b shadow-md" role="alert">
        <div className="flex">
          <div className="py-1"><svg className="w-6 h-6 mr-4 text-teal-500 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
          <div>
            <h2 className="font-bold">Inicia Sesión Administración</h2>
            <p className="text-sm">Si no eres administrador/a no deberías estar acá</p>
          </div>
        </div>
      </div>
      <Formik
        enableReinitialize
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors, touched, values,
        }) => (
          <Form className="px-8 pt-6 pb-8 mb-4 bg-white rounded-md shadow-md">
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-800"
              >
                Email
                <div className="flex">
                  <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-400 rounded-l-md ">
                    <MdAlternateEmail />
                  </span>
                  <Field
                    id="email"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    placeholder="ejemplo@gmail.com"
                    className="rounded-none rounded-r-lg bg-gray-50 border border-gray-600 text-gray-700 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm  p-2.5 "
                  />
                </div>
                {(errors.email && touched.email) && (
                  <p>{errors.email}</p>
                )}
              </label>
            </div>

            <div className="mb-4">
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-800"
              >
                Contraseña
                <div className="flex">
                  <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-400 rounded-l-md ">
                    <RiLockPasswordFill />
                  </span>
                  <Field
                    id="password"
                    type="password"
                    name="password"
                    placeholder="*********"
                    className="rounded-none rounded-r-lg bg-gray-50 border border-gray-600 text-gray-700 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm  p-2.5 "
                  />
                </div>
                {(errors.password && touched.password) && (
                  <p>{errors.password}</p>
                )}

              </label>
            </div>

            <div className="flex items-center justify-between">
              <button
                type="submit"
                disabled={!(values.email && values.password)}
                className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 disabled:bg-blue-300 focus:outline-none focus:shadow-outline"
              >
                Iniciar Sesión
              </button>
            </div>
            <p className="text-red-500">{error}</p>
          </Form>
        )}
      </Formik>
    </div>
  );
}
