import React from 'react';

export default function Copyright() {
  return (
    <div className="px-2 py-5">
      <div className="overflow-x-auto">
        <div className="max-w-xl mx-auto overflow-hidden bg-white divide-y divide-gray-300 rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2
              className="pb-4 text-2xl font-bold text-center text-dope-purple"
            >
              Derechos de autor
            </h2>
            <p className="py-2 text-justify indent-8">
              En la incorporación de todos los cantos se ha respetado la propiedad intelectual de sus autores, tanto de las letras como de la composición musical completa (letra y partitura). Estos datos figuran en la parte superior central de la página de la letra y/o partitura correspondiente. La única excepción la constituyen los cantos de dominio público o aquellos populares sin autor conocido, o anónimos. Cuando no se han encontrado partituras de algunos cantos, ellas han sido elaboraciones de
              {' '}
              <a href="https://www.linkedin.com/in/luis-vidal-maturana-704732ab/" className="font-bold text-blue-600 hover:text-blue-800 hover:underline">Luis Vidal Maturana</a>
              ,
              en cuyo caso, su nombre figura en la parte inferior de la misma (autor de la partitura). En algunos casos, se han hecho adaptaciones o simplificaciones de las partituras de autor conocido; para respetar la propiedad intelectual en ambos supuestos, el autor de la composición figura en la parte superior, y el autor del arreglo musical o adaptación, en la inferior. Cuando se ha hecho alguna traducción de obra de autor conocido y de dominio privado, esta adaptación figura en la parte inferior de la página (Ej: traducción de Nombre Apellido).
            </p>

            <p className="py-2 text-justify indent-8">
              La propiedad de este cancionero digital es de la
              {' '}
              <span className="font-bold">Fundación  de Educación y Sostenimiento del Culto (Educulto), RUT: 53.334.187-K</span>
              .
            </p>

            <p className="py-2 text-justify indent-8">
              El creador de esta página web,
              {' '}
              <a href="https://www.linkedin.com/in/jose-antonio-castro-guevara/" className="font-bold text-blue-600 hover:text-blue-800 hover:underline">Jose Antonio Castro Guevara</a>
              , ha cedido sus derechos de autor a la Fundación contra el pago de la ejecución y terminación de su trabajo.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
